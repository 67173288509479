import {getToken} from "./userCredentials";

const axios = require("axios");

export const getOHPbyID = async (id) =>{

    let res ="";
    try {
        res = await axios.get(process.env.VUE_APP_DB + "/api2/ownhalalprovisionbyhid/"+id,{headers: {"authorization": 'Bearer ' + await getToken()}})
    }
    catch(e)
    {

    }


    return  res.data.data;
}