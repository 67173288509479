<template>
  <div class="div-cardplace">

    <div class="div-inner-cardplace">
      <div class="div-img-cardplace" @click="zuPlace(hotelinfos.place.id)">
        <img
            class="img-cardplace"
            :src='hotelinfos.place.photo'
            alt="Foto-Hotel"
        />

      </div>


      <div class="div-inner-infos-cardplace">
        <div class="div-inner-infos1-cardplace" @click="zuPlace(hotelinfos.place.id)">
          <div v-if="hotelinfos.place.stars >0 " style="height: 30px;">

            <v-rating
                v-model="hotelinfos.place.stars"
                background-color="transparent"
                color="#FFDF00"
                small
                readonly
            ></v-rating>
          </div>
          <div v-else style="height: 30px"></div>


          <div>
            <label class="label-hoteltitel-cardplace">
              {{ hotelinfos.place.name }}
            </label>
          </div>


          <div class="div-location-cardplace">
            <v-icon small>mdi-map-marker</v-icon>
            <label>{{ hotelinfos.place.location.city }}, </label>
            <label>{{ hotelinfos.place.location.subregion }}, </label>
            <label>{{ hotelinfos.place.location.country }}</label>
          </div>


          <div>
            <div class="div-mealplan-cardplace">
              <v-icon>mdi-room-service</v-icon>
              <label>
                {{ hotelinfos.groups[0].offers[0].rate_plan.meal_plan_name }}
              </label>
            </div>
            <div>
              <label class="label-mealplan-cardplace" v-if="hotelinfos.groups[0].offers[0].rate_plans.length >1">
                {{ $t("Card.EbensoBuchbar") }}:

                <template v-for="(i, index) in hotelinfos.groups[0].offers[0].rate_plans">
                  <label v-if="index >0">
                    {{ i.rate_plan.meal_plan_name }}
                    <template
                        v-if="index < hotelinfos.groups[0].offers[0].rate_plans.length-1"
                    >,
                    </template>
                  </label>

                </template>
              </label>
            </div>
          </div>
        </div>

        <div style="text-align: center">
          <div>
            <div class="div-price-inner-cardplace">
<!--              <v-icon small color="var(&#45;&#45;Primary)">{{ currencyicon }}</v-icon>-->
              <v-icon small color="var(--Primary)">{{ currencyicon }}</v-icon>
              <label class="label-price-cardplace vicon-color-linear-gradient">
                {{ formatPrice(hotelinfos.min_price) }}*
              </label>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div class="div-priceinfo-cardplace" v-on="on">
                  *{{ $t("Card.Gesamtpreis") }}

                  <span v-if="hotelinfos.quantity > 1">
                    <br>
                    <span v-if="lang !== 'TR'">{{ $t("Card.fuer") }} </span>
                    {{ hotelinfos.quantity }} {{ $t("Card.Zimmer") }}

                    <span v-if="lang === 'TR'">{{ $t("Card.fuer") }}</span>

                  </span>
                  <v-icon small>mdi-information-outline</v-icon>
                </div>
              </template>
              <div class="tooltip-price-cardplace">
                {{ $t("Card.TTPreis1") }}
                <br>
                {{ $t("Card.TTPreis2") }}
              </div>
            </v-tooltip>
          </div>

          <div>
            <!--            <v-btn-->
            <!--                small-->
            <!--                color="var(&#45;&#45;Primary)"-->
            <!--                class="vbtn-cardplace"-->
            <!--                @click="zuPlace(hotelinfos.place.id)"-->
            <!--            >-->
            <!--              {{ $t("Button.Details") }}-->
            <!--            </v-btn>-->
            <v-btn
                small
                class="vbtn-cardplace vbtn-linear-gradient"
                @click="zuPlace(hotelinfos.place.id)"
            >
              {{ $t("Button.Details") }}
            </v-btn>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>


<script>
import {formatPrice, getCurrencyIcon} from "../../util/currency";

export default {
  name: "CardPlace",
  props: [
    'hotelinfos',
    'checkin',
    'checkout',
    'groups',
    'from',
    'lang'
  ],
  data() {
    return {
      // selectLang: 'DE',
      datetoday: new Date().toISOString(),
      currencyicon: getCurrencyIcon()
    }
  },
  methods: {
    getCurrencyIcon,
    formatPrice,
    zuPlace(id) {
      let r = this.$router.resolve({
        path: 'place',
        query: {id: id, checkin: this.checkin, checkout: this.checkout, groups: this.groups}
      });
      window.open(r.href);

    },

  }

}

</script>

<style lang="css" scoped>
@import "../../css/CardPlace.css";
</style>
