import axios from "axios";
import jwt from "jsonwebtoken";
import {getToken} from "@/util/userCredentials";


export const getAdminUser = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/auser", {headers: {"authorization": 'Bearer ' + await getToken()}});
    let user = res.data.data;

    return user;
}

