import axios from "axios";
import moment from "moment";
import {getToken} from "@/util/userCredentials";


export const getCurrencyValues = async (key) => {
    let today = moment().format("YYYY-MM-DD");
    let values = {
        key: key
    }
    let res = await axios.post(process.env.VUE_APP_DB + "/api2/cvalues/", values, {headers: {"authorization": 'Bearer ' + await getToken()}});


    return res.data.data.data;

    // Wenn Efe bei Currency meckert
    // return res.data.data.rates[today]
}

export const calculateCurrency = (currency, newcurrency, currencyValues, price) => {
    // console.log('currency, newcurrency, currencyValues, price');
    // console.log(currency, newcurrency, currencyValues, price);
    if (price === undefined ||currencyValues === "" ||newcurrency === "" ||currency === "" ||
        currencyValues === undefined ||newcurrency === undefined ||currency === undefined) {
        return 0;
    }

    let cvalue = currencyValues[newcurrency.toUpperCase()].value;
    let oldvalue = currencyValues[currency.toUpperCase()].value;


    // Wenn Efe bei Currency meckert
    // let cvalue = currencyValues[newcurrency.toUpperCase()];
    // let oldvalue = currencyValues[currency.toUpperCase()];

    //

    if (currency === "USD") {

        if (newcurrency !== "USD") {
            return (price * cvalue).toFixed(2);
        } else {
            return price.toFixed(2);
        }
    }


    if (currency === "EUR") {
        if (newcurrency === "TRY") {
            return calculatePrice(price, oldvalue, cvalue).toFixed(2);
        }

        if (newcurrency === "USD") {
            return (price / oldvalue).toFixed(2);

        }
        return price.toFixed(2);

    }

    if (currency === "TRY") {
        if (newcurrency === "EUR") {
            return calculatePrice(price, oldvalue, cvalue).toFixed(2);
        }

        if (newcurrency === "USD") {
            return (price / oldvalue).toFixed(2);

        }
        return price.toFixed(2);

    }

    return price.toFixed(2);


}

const calculatePrice = (price, oldvalue, cvalue) => {

    let zp = price / oldvalue;
    return zp * cvalue;
}


export const getCurrencyKey = async () => {
    let status = 0;

    let res = await axios.get(process.env.VUE_APP_DB + "/api2/curset", {headers: {"authorization": 'Bearer ' + await getToken()}}).catch(err => {
    });

    let entry = res.data.data[0];
    return entry;
}