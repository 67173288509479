import moment from "moment";


export const getDayPrice = (price, checkin, checkout, min_nights) => {


    let gprice = 0.00;
    let found = false;


    checkin = new Date(checkin).getTime();
    checkout = new Date(checkout).getTime();

    let dTime = Math.abs(checkout - checkin);
    let dDays = Math.ceil(dTime / (1000 * 60 * 60 * 24));


    let first = false;
    let i = 0;
    price.map((item, index) => {

        let t = new Date(item.startdate).setHours(0, 0, 0, 0);


        if (checkin >= new Date(t).getTime() || first) {
            first = true;


            let start = new Date(item.startdate);
            start.setHours(0, 0, 0, 0);
            let end = new Date(item.enddate);
            end.setHours(0, 0, 0, 0);
            let checkin1 = new Date(checkin);
            checkin1.setHours(0, 0, 0, 0);
            let checkout1 = new Date(checkout);
            checkout1.setHours(0, 0, 0, 0);

            let a = new Date(start).getTime() <= new Date(checkin1).getTime() ? new Date(checkin1).getTime() : new Date(start).getTime();


            if (new Date(start.getTime()) <= a && new Date(end).getTime() >= new Date(checkin1).getTime()) {

                if (new Date(end).getTime() >= new Date(checkout1).getTime()) {

                    if (!found) {

                        let diffTime = Math.abs(a - new Date(checkout1).getTime());
                        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                        if (dDays < min_nights) {
                            diffDays = min_nights;
                        }


                        let zprice = (diffDays) * item.price;

                        gprice += zprice;

                        found = true;

                        i += 1;

                        return gprice;
                    } else {

                    }
                } else {


                    let diffTime = Math.abs(a - new Date(end).getTime());
                    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    let zprice = (diffDays + 1) * item.price;


                    gprice += zprice;
                    i += 1;

                }


            } else {

            }
        }

    });

    return gprice;
}
//gpPpT = Gesamtpreis pro Person pro Tag
//ppT = preis pro Tag
// priceRatePlan = Ausgewählter RatePlan
//minMulti = min Multiplikator
//zimMin = Zimmer min Kapazität
//zimMax = Zimmer max Kapazität
//BabyZust= Baby Zustell betten
//kinder
//baby
//urTage = Urlaubstage
//aufpZi = Aufpreis Zimmer
//min_nights = Mindestnächte

export const calculatePrice = (erwachsene, kinder, baby, ppT, priceRatePlan, aufpZi, zimMin, zimMax, babyZust, kinderRabat, babyRabat, anzahlNaechte, min_nights) => {

    let ki_rabatt = (100 - kinderRabat) / 100;
    let ba_rabatt = (100 - babyRabat) / 100;


    let sumPersonen = erwachsene + kinder;
    let babyUber = 0;


    if ((baby - babyZust) > 0) {
        babyUber = (baby - babyZust);
    }
    if (anzahlNaechte < min_nights) {
        anzahlNaechte = min_nights;
    }

    let raum = Math.ceil(((erwachsene + kinder + babyUber) / zimMax));

    if (aufpZi === null || aufpZi === undefined || aufpZi ===''){
        aufpZi= 0;
    }

    let gpPpT = ppT + ((priceRatePlan + (aufpZi * raum)) * anzahlNaechte);
    // console.log("ppT + ((priceRatePlan + (aufpZi * raum)) * anzahlNaechte)");
    // console.log(gpPpT + " = " + ppT + " + " + "((" + priceRatePlan + "+" + "(" + aufpZi + "*" + raum + ")) *" + anzahlNaechte + "))");

    let summezwischenfaktor = 0;
    let gesamtmultiplikator = 0;

    if (zimMin * raum >= sumPersonen) {
        gesamtmultiplikator = zimMin * raum;

    } else {
        summezwischenfaktor = zimMin * raum;
        if (erwachsene > summezwischenfaktor) {
            let erwachsenerest = erwachsene - summezwischenfaktor;
            gesamtmultiplikator = (erwachsenerest + (kinder * ki_rabatt) + (baby * ba_rabatt)) + summezwischenfaktor;
        } else {
            let a = summezwischenfaktor - erwachsene;
            if (a > 0) {

                let t = kinder - a;
                gesamtmultiplikator = (t * ki_rabatt) + (baby * ba_rabatt) + summezwischenfaktor;


            } else {
                gesamtmultiplikator = (kinder * ki_rabatt) + (baby * ba_rabatt) + summezwischenfaktor;
            }

        }
    }


    let tagespreis = gpPpT * gesamtmultiplikator;

    // console.log('tagespreis = gpPpT * gesamtmultiplikator');
    // console.log(tagespreis, ' = ', gpPpT, ' * ', gesamtmultiplikator);

    let gesamtpreis = Math.trunc(tagespreis);

    return gesamtpreis;

}


export const getLowestPrice = (erwachsene, kinder, baby, checkin, checkout, place, anzahlNaechte) => {
    let lowestPrice = 0.00;


    place.rooms.map(item => {

        if (item.status_room === 1) {
            if (!checkSalestop(checkin, checkout, item.salestops)) {

                let ppT = getDayPrice(place.price, checkin, checkout, item.min_nights);

                // console.log("ppt", ppT);
                if (ppT !== 0) {
                    // console.log("ppt", ppT);
                    let price = calculatePrice(
                        erwachsene,
                        kinder,
                        baby,
                        ppT,
                        place.rate_plans[0].price,
                        item.aufpreis,
                        item.min_persons,
                        item.max_persons,
                        item.baby_bett,
                        place.children_price.rabatt,
                        place.baby_price.rabatt,
                        anzahlNaechte,
                        item.min_nights
                    )

                    // console.log("price", price);
                    item.price = price;
                    let babyUber = 0;
                    if ((baby - item.baby_bett) > 0) {
                        babyUber = (baby - item.baby_bett);
                    }

                    item.anzahl_zimmer = Math.ceil(((erwachsene + kinder + babyUber) / item.max_persons));
                    // console.log('item.anzahl_zimmer= ', item.anzahl_zimmer);
                    //
                    //
                    // console.log(lowestPrice);
                    // console.log(price);
                    if (lowestPrice === 0.00) {
                        lowestPrice = price;

                    } else {
                        if (lowestPrice > price) {
                            lowestPrice = price;
                        }
                    }
                }
            }
        }
    })
    return lowestPrice;
}


export const checkChildrenAge = (gruppe, index, babies, children) => {
    //  console.log("check Age gruppe: ", gruppe);

    let o = {
        erwachsene: gruppe[index].erwachsene,
        children: 0,
        babies: 0
    }

    //console.log("children: ", children);
    //console.log("babies: ", babies);

    gruppe[index]
        .kinderalter.map(it => {
        if (parseInt(it.age) <= parseInt(babies.age_end)) {
            o.babies = o.babies + 1;
        }
        if (parseInt(it.age) > parseInt(babies.age_end) && parseInt(it.age) <= parseInt(children.age_end)) {
            o.children = o.children + 1;
        }
        if (parseInt(it.age) > parseInt(children.age_end)) {
            o.erwachsene = o.erwachsene + 1;
        }
    })
    // console.log(o);

    return o;

}


const checkSalestop = (start, end, salestops) => {
    let checksalestop = false;
    let checkin = new Date(start);
    start.setHours(0, 0, 0, 0);
    let checkout = new Date(end);
    end.setHours(0, 0, 0, 0);
    salestops.map(item => {
        let s = new Date(item.start);
        s.setHours(0, 0, 0, 0);
        let e = new Date(item.end);
        e.setHours(0, 0, 0, 0);
        // SaleStop 02.05 - 07.05
        // SaleStop 08.05 - 11.05
        // SaleStop 06.06 - 10.06
        // Checkin  01.05 - 13.05

        if (s.getTime() <= checkin.getTime() && checkin.getTime() <= e.getTime()) {
            checksalestop = true;
        }
        if (s.getTime() <= checkout.getTime() && checkout.getTime() <= e.getTime()) {
            checksalestop = true;
        }
        if (s.getTime() >= checkin.getTime() && checkout.getTime() >= e.getTime()) {
            checksalestop = true;
        }
    })


    return checksalestop;

}