<template>
  <div class="home">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <div class="div-search-start">

      <div class="div-background-start"/>

      <div class="div-carousel-start">
        <v-carousel
            hide-delimiters
            :show-arrows="false"
            cycle
            interval="6000"
            style="height: 400px;"
        >
          <v-carousel-item
              v-for="(item,i) in pics"
              :key="i"
              :src="item"
          ></v-carousel-item>
        </v-carousel>
      </div>
      <div class="div-suche-start">

        <Search
            :props-searchtext="location"
            :propsHolidayType="holiday_type"
            :halalHotels="halalHotels"
            :groups="groups"
            from="home"
            :lang="$attrs.lang"
            :pLang="pLang"
            :pFavSearch="pFavSearch"
        />
      </div>


    </div>


    <div class="div-body-home">
      <!--      <div>-->
      <!--        <Search-->
      <!--            :halalHotels="halalHotels"-->
      <!--            from="home"-->

      <!--        />-->
      <!--      </div>-->

      <h3 style="text-align: center; margin-top: 50px;">
        {{ $t("Home.Urlaubsziele") }}
      </h3>


      <div v-if="loading" style="text-align: center">
        <!--      <div v-if="true">-->
        <v-progress-circular
            indeterminate
            color="primary"
            :size="50"
            :width="7"

        />
        <SkeletonCardHorizontal/>
      </div>

      <div class="div-card-home">
        <template v-for="(info, index) in this.ownPlaces" >
          <div v-if="info.min_price !== 0.00" :key="index+intOwnPlaces">
            <CY_CardPlace
                :hotelinfos="info"
                :checkin="checkin"
                :checkout="checkout"
                :groups="groups"
                :pLang="pLang"
                from="home"
            />
          </div>
        </template>
        <template v-for="(info,index) in this.halalHotels.offers">
          <CardPlace
              :hotelinfos="info"
              :checkin="checkin"
              :checkout="checkout"
              :groups="groups"
              :lang="$attrs.lang"
          />
        </template>
      </div>
      <div v-if="this.halalHotels.offers">
        <div v-if="this.halalHotels.offers.length < 1" class="div-norooms-home">
          <v-icon large color="error">mdi-calendar-search</v-icon>
          <label>
            {{ $t("Place.KeineErgebnisse") }}
          </label>
        </div>
      </div>

      <div v-if="moreloading" style="text-align: center">
        <v-progress-circular
            indeterminate
            color="primary"
            :size="50"
            :width="7"

        />

        <SkeletonCardHorizontal/>
      </div>

      <div v-if="this.halalHotels.offers">
        <div class="div-paging-home" v-if="this.halalHotels.offers.length >0">
          <v-btn @click="weitereErgebnisse" color="var(--Primary)" class="vbtn-more-home">
            <v-icon>mdi-chevron-down</v-icon>
            {{ $t("Button.WeitereErgebnisse") }}
          </v-btn>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {callapi, getALLHalalPlaces, getALLHalalPlacesWithgroups, getInitPlaces} from "../util/halalapi";
import CardPlace from "../components/card/CardPlace";
import Search from "../components/search/Search";
import SkeletonCardHorizontal from "../components/skeletons/SkeletonCardHorizontal";
import CY_CardPlace from "@/components/cy_card/CY_CardPlace";
import moment from "moment";
import Meer1 from "../assets/Fotos/Meer1.jpg";
import Meer2 from "../assets/Fotos/Meer2.jpg";
import Meer3 from "../assets/Fotos/Meer3.jpg";
import Meer4 from "../assets/Fotos/Meer4.jpg";
import Meer5 from "../assets/Fotos/Meer5.jpg";
import Meer6 from "../assets/Fotos/Meer6.jpg";
import Meer7 from "../assets/Fotos/Meer7.jpg";
import * as UrlUtil from "../util/UrlUtil";
import {getGroups} from "../util/util";
import {getOwnPlaces} from "@/util/OwnPlace";
import {getCountry} from "@/util/Countries";
import {checkChildrenAge, getLowestPrice} from "@/util/PriceOwnPlaceUtil";
import {calculateCurrency, getCurrencyKey, getCurrencyValues} from "@/util/currencyUtil";

export default {
  name: "Home",
  props: [
    'pLang',
    'pCurrencyValues',
    'pCurrency',
    'pFavSearch'
  ],
  components: {
    CardPlace,
    CY_CardPlace,
    Search,
    SkeletonCardHorizontal
  },
  data() {
    return {

      user: null,
      isadmin: true,
      ishotel: false,
      isreiseburo: false,

      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      pics: [Meer1, Meer2, Meer3, Meer4, Meer5, Meer6, Meer7],

      loading: true,
      ownHalalPlaces: {},
      halalHotels: [],
      page: 1,
      moreloading: false,


      //  Eigene Places
      ownPlaces: [],
      selectedcheckin: "",
      selectedcheckout: "",
      selectedstartdate: "",
      selectedenddate: "",
      anzahlNaechte: 0,
      intOwnPlaces: 0,
      currencyValues: "",

      // selectedholidayType: "hotel",
      // holidayTypes: "thermal",

      checkin: "",
      checkout: "",
      groups: "&groups[]=2",
      url: "",
      location: "",

      holiday_type: "",
      gruppe: ""


    };
  },
  methods: {
    getGroups,
    async weitereErgebnisse() {

      this.moreloading = true;
      let g = this.getGroups();
      this.page = this.page + 1;

      this.location = this.$route.query.name;
      if (this.$route.query.holiday_type === "") {

        let query = {...this.$route.query};
        delete query.holiday_type;
        try {
          await this.$router.replace({query: {...query, holiday_type: "xx"}});
        } catch (e) {

        }

      }
      if (this.location === "") {
        if (this.url === "") {
          let erg = await getInitPlaces(this.page);
          this.halalHotels.offers.push(...erg.offers);

        } else {


          let data = await callapi(this.url + "&" + g, this.page);

          this.halalHotels.offers.push(...data.offers);
        }
      } else {
        if (this.location === undefined) {
          let url = this.$route.fullPath;
          url = url.replace("/home", "");
          let data = await callapi(url + "&" + g, this.page);
          this.halalHotels.offers.push(...data.offers);
        } else {
          let data = await getALLHalalPlaces(this.location, this.page);

          this.halalHotels.offers.push(...data.offers);
        }
      }


      this.moreloading = false;
    }
  },


  // async created() {
  //
  //   window.scrollTo(0, 0);
  // },

  async mounted() {

    if (this.$route.query.name !== undefined) {
      this.location = this.$route.query.name;
    }

    if (this.$route.query.searchtext !== undefined) {
      this.location = this.$route.query.searchtext;
    }

    if (this.location === undefined) {
      if (this.$route.query.searchtext !== undefined) {
        this.location = this.$route.query.searchtext;
      }
    } else {
      this.location = this.$route.query.name;
    }
    let url = this.$route.fullPath;

    let holiday_type = this.$route.query.holiday_type;


    if (holiday_type === "Tüm temalar" || holiday_type === "Alle Themen" || holiday_type === "Any theme") {
      this.holiday_type = "xx";
    }
    if (holiday_type === "Termal" || holiday_type === "Wellness" || holiday_type === "Wellness") {
      this.holiday_type = "thermal";
    }
    if (holiday_type === "Villalar" || holiday_type === "Villen" || holiday_type === "Villas") {
      this.holiday_type = "villa";
    }
    if (holiday_type === "Tatil köyleri" || holiday_type === "Resorts" || holiday_type === "Resorts") {
      this.holiday_type = "resort";
    }

    if (holiday_type === "Şehir tatili" || holiday_type === "Städtereise" || holiday_type === "City Break") {
      this.holiday_type = "hotel";
    }

    if (this.holiday_type === "") {
      this.holiday_type = this.$route.query.holiday_type;
    }


    let query = {...this.$route.query};
    delete query.holiday_type;

    try {
      await this.$router.replace({query: {...query, holiday_type: holiday_type}});
    } catch (e) {

    }


    url = url.replace("/home", "");


    // this.$root.$on("search", (val) => {
    //   this.halalHotels = val;
    // });

    this.$root.$on("loading", (val) => {
      this.loading = val;
    });
    this.$root.$on("checkin", (val) => {
      this.checkin = val;
    });
    this.$root.$on("checkout", (val) => {
      this.checkout = val;
    });
    this.$root.$on("groups", (val) => {
      this.groups = val;
    });
    this.$root.$on("url", (val, p) => {
      this.url = val;
      if (p !== undefined) {
        this.page = p;
      }
    });

    if (JSON.parse(localStorage.getItem("gruppe")) !== null) {
      this.gruppe = JSON.parse(localStorage.getItem("gruppe"));
    } else {
      this.gruppe = [{erwachsene: 2, kinder: 0, kinderalter: [], name: "Gruppe 1"}]
    }

    let value = "";
    let url1 = "";
    this.gruppe.map((item, index) => {
      let groups = "";
      if (index > 0)
        UrlUtil.bind(url1);
      value = UrlUtil.binddata(value);

      let erw = item.erwachsene;
      let kinder = item.kinder;

      if (kinder > 0) {
        item.kinderalter.map(it => {
          if (groups !== "")
            groups = groups.concat(",");

          groups = groups.concat(it.age.toString());
        });
      }

      if (kinder > 0) {
        url1 = UrlUtil.addGroups(url1, erw + "," + groups);
        value = UrlUtil.addGroupsData(value, erw + "," + groups);
      } else {
        url1 = UrlUtil.addGroups(url1, erw);
        value = UrlUtil.addGroupsData(value, erw);
      }


    });

    url = url + "&" + url1;
    this.groups = value;

    this.ownPlaces = await getOwnPlaces();
    if (localStorage.getItem('desP')) {
      this.ownPlaces = this.ownPlaces.filter(item =>
          (localStorage.getItem('desP').toLowerCase() === getCountry("EN", item.location.country).toLowerCase())
      )

    }

    let g = this.gruppe;

    this.ownPlaces.map((item, index) => {
      if (item.rooms) {
        let p = 0.00;
        if (g !== undefined && g !== null) {

          g.map((it, i) => {
            let o = checkChildrenAge(g, i, item.baby_price, item.children_price);
            p += getLowestPrice(o.erwachsene, o.children, o.babies, this.selectedstartdate, this.selectedenddate, item, this.anzahlNaechte);


          })

        } else {
          p = getLowestPrice(2, 0, 0, this.selectedstartdate, this.selectedenddate, item, this.anzahlNaechte);

        }
        // this.ownPlaces[index].min_price = p;

        let x = calculateCurrency(this.ownPlaces[index].currency, this.pCurrency, this.pCurrencyValues, p);
        this.ownPlaces[index].min_price = x;

      }
    })



    if (this.location === "") {
      let page = 1;
      this.halalHotels = await getInitPlaces(page);
    } else {


      if (this.$route.query.name === undefined) {
        this.halalHotels = await callapi(url, 1);
      } else {
        this.halalHotels = await getALLHalalPlacesWithgroups(this.location, 1, this.groups);
      }


    }


    if (this.halalHotels !== {}) {
      this.loading = false;
    }

  },
  async created() {

    // await new Promise(resolve => setTimeout(resolve, 300));
    window.scrollTo(0, 2);


    // ------------------------------------ DATUM anzeigen ------------------------------------
    if (localStorage.getItem('c-in') !== null) {
      let c_in = new Date(localStorage.getItem('c-in'));

      this.checkin = moment(c_in).format("YYYY-MM-DD");
      this.selectedstartdate = c_in;

    } else {

      let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
      this.checkin = moment(d).format("YYYY-MM-DD");
      this.selectedstartdate = d;
    }

    if (localStorage.getItem('c-out') !== null
    ) {
      let c_out = new Date(localStorage.getItem('c-out'));
      this.checkout = moment(c_out).format("YYYY-MM-DD");
      this.selectedenddate = c_out;
    } else {

      let d1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
      this.checkout = moment(d1).format("YYYY-MM-DD");
      this.selectedenddate = d1;
    }

    // ------------------------------------ DATUM anzeigen ENDE ------------------------------------

    // Berechnung der Nächte
    let days = null;
    let miliseconds = this.selectedenddate.getTime() - this.selectedstartdate.getTime();
    let total_seconds = parseInt(Math.floor(miliseconds / 1000));
    let total_minutes = parseInt(Math.floor(total_seconds / 60));
    let total_hours = parseInt(Math.floor(total_minutes / 60));
    days = parseInt(Math.floor(total_hours / 24));
    this.anzahlNaechte = days;


    if (JSON.parse(localStorage.getItem("gruppe")) !== null) {
      this.gruppe = JSON.parse(localStorage.getItem("gruppe"));
    } else {
      this.gruppe = [{erwachsene: 2, kinder: 0, kinderalter: [], name: "Gruppe 1"}]
    }


    if (this.$route.query.name !== undefined) {
      this.location = this.$route.query.name;
    }

    if (this.$route.query.searchtext !== undefined) {
      this.location = this.$route.query.searchtext;
    }

    if (this.location === undefined) {
      if (this.$route.query.searchtext !== undefined) {
        this.location = this.$route.query.searchtext;
      }
    } else {
      this.location = this.$route.query.name;
    }
    let url = this.$route.fullPath;


    let holiday_type = this.$route.query.holiday_type;


    if (holiday_type === "Tüm temalar" || holiday_type === "Alle Themen" || holiday_type === "Any theme") {
      this.holiday_type = "xx";
    }
    if (holiday_type === "Termal" || holiday_type === "Wellness" || holiday_type === "Wellness") {
      this.holiday_type = "thermal";
    }
    if (holiday_type === "Villalar" || holiday_type === "Villen" || holiday_type === "Villas") {
      this.holiday_type = "villa";
    }
    if (holiday_type === "Tatil köyleri" || holiday_type === "Resorts" || holiday_type === "Resorts") {
      this.holiday_type = "resort";
    }

    if (holiday_type === "Şehir tatili" || holiday_type === "Städtereise" || holiday_type === "City Break") {
      this.holiday_type = "hotel";
    }

    if (this.holiday_type === "") {
      this.holiday_type = this.$route.query.holiday_type;
    }


    let query = {...this.$route.query};
    delete query.holiday_type;

    try {
      await this.$router.replace({query: {...query, holiday_type: holiday_type}});
    } catch (e) {

    }


    url = url.replace("/home", "");


    // this.$root.$on("search", (val) => {
    //   this.halalHotels = val;
    // });

    this.$root.$on("loading", (val) => {
      this.loading = val;
    });
    this.$root.$on("checkin", (val) => {
      this.checkin = val;
    });
    this.$root.$on("checkout", (val) => {
      this.checkout = val;
    });
    this.$root.$on("groups", (val) => {
      this.groups = val;
    });
    this.$root.$on("url", (val, p) => {
      this.url = val;
      if (p !== undefined) {
        this.page = p;
      }
    });

    if (JSON.parse(localStorage.getItem("gruppe")) !== null) {
      this.gruppe = JSON.parse(localStorage.getItem("gruppe"));
    } else {
      this.gruppe = [{erwachsene: 2, kinder: 0, kinderalter: [], name: "Gruppe 1"}]
    }

    let value = "";
    let url1 = "";
    this.gruppe.map((item, index) => {
      let groups = "";
      if (index > 0)
        UrlUtil.bind(url1);
      value = UrlUtil.binddata(value);

      let erw = item.erwachsene;
      let kinder = item.kinder;

      if (kinder > 0) {
        item.kinderalter.map(it => {
          if (groups !== "")
            groups = groups.concat(",");

          groups = groups.concat(it.age.toString());
        });
      }

      if (kinder > 0) {
        url1 = UrlUtil.addGroups(url1, erw + "," + groups);
        value = UrlUtil.addGroupsData(value, erw + "," + groups);
      } else {
        url1 = UrlUtil.addGroups(url1, erw);
        value = UrlUtil.addGroupsData(value, erw);
      }


    });



    url = url + "&" + url1;

    this.groups = value;

    this.ownPlaces = await getOwnPlaces();
    if (localStorage.getItem('desP')) {
      this.ownPlaces = this.ownPlaces.filter(item =>
          (localStorage.getItem('desP').toLowerCase() === getCountry("EN", item.location.country).toLowerCase())
      )

    }

    let g = this.gruppe;

    let ckey = await getCurrencyKey();

    this.currencyValues = await getCurrencyValues(ckey.currencyKey);

    this.ownPlaces.map((item, index) => {
      if (item.rooms) {
        let p = 0.00;
        if (g !== undefined && g !== null) {

          g.map((it, i) => {
            let o = checkChildrenAge(g, i, item.baby_price, item.children_price);
            p += getLowestPrice(o.erwachsene, o.children, o.babies, this.selectedstartdate, this.selectedenddate, item, this.anzahlNaechte);


          })

        } else {
          p = getLowestPrice(2, 0, 0, this.selectedstartdate, this.selectedenddate, item, this.anzahlNaechte);

        }
        // this.ownPlaces[index].min_price = p;

        let x = calculateCurrency(this.ownPlaces[index].currency, this.pCurrency, this.currencyValues, p);



        this.ownPlaces[index].min_price = parseFloat(x);

        this.intOwnPlaces++;

      }
    })


    if (this.location === "") {
      let page = 1;
      this.halalHotels = await getInitPlaces(page);
    } else {


      if (this.$route.query.name === undefined) {
        this.halalHotels = await callapi(url, 1);
      } else {
        this.halalHotels = await getALLHalalPlacesWithgroups(this.location, 1, this.groups);
      }


    }


    if (this.halalHotels !== {}) {
      this.loading = false;
    }
  }

}
</script>
<style scoped>
@import "../css/Home.css";
</style>