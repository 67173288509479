import axios from "axios";
import {getToken} from "@/util/userCredentials";

export const createOwnPlace = async (place) => {
    let res = await axios.post(process.env.VUE_APP_DB + "/api2/oplace/", place, {headers: {"authorization": 'Bearer ' + await getToken()}});
    return res.status;
}

export const getOwnPlaces = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/oplaces/", {headers: {"authorization": 'Bearer ' + await getToken()}});
    return res.data.data;
}

export const getAllOwnPlaces = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/aoplaces/", {headers: {"authorization": 'Bearer ' + await getToken()}});
    return res.data.data;
}

export const getOwnPlaceByPlaceID = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/oplacebpid/" + id, {headers: {"authorization": 'Bearer ' + await getToken()}});
    return res.data.data;
}

export const getOwnPlaceByPlaceHID = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/oplacebbyhid/" + id, {headers: {"authorization": 'Bearer ' + await getToken()}});
    return res.data.data;
}
export const getOwnPlaceBySearch = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/oplacebbys/" + id, {headers: {"authorization": 'Bearer ' + await getToken()}});
    return res.data.data;
}
export const getOwnPlaceByCY = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/oplacecy/" + id, {headers: {"authorization": 'Bearer ' + await getToken()}});
    return res.data.data;
}

export const updateOwnPlace = async (place) => {
    let status = 0;
    await axios.put(process.env.VUE_APP_DB + "/api2/oplace/" + place._id, place, {headers: {"authorization": 'Bearer ' + await getToken()}}).then(res => status = res.status).catch(err => {
    });
    return status;
}


